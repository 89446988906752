import Vue from 'vue';
import VueApollo from 'vue-apollo';
import {
    ApolloClient,
    ApolloLink,
    InMemoryCache,
    HttpLink,
} from 'apollo-boost';
import router from '@/router';

import { onError } from 'apollo-link-error';
import store from '@/store';

Vue.use(VueApollo);

const AppUrl = new HttpLink({ uri: process.env.VUE_APP_URL });

const Auth = new ApolloLink((operation, forward) => {
    const token = store.state.login.access;

    operation.setContext({
        headers: {
            Authorization: token ? `JWT ${token}` : '',
        },
    });

    // Call the next link in the middleware chain.
    return forward(operation);
});

const Errors = onError(({ graphQLErrors }) => {
    if (graphQLErrors) {
        for (const err of graphQLErrors) {
            if (err.message === 'Unauthorized') {
                localStorage.removeItem('token');
                router.replace({ name: 'Auth' });
            }
        }
    }
});

const link = ApolloLink.from([Auth, Errors, AppUrl]);

export const apolloClient = new ApolloClient({
    link,
    cache: new InMemoryCache(),
    defaultOptions: {
        query: { fetchPolicy: 'no-cache' },
    },
});

const apolloProvider = new VueApollo({
    defaultClient: apolloClient,
});

export default apolloProvider;
