import { Module } from 'vuex';

import mutations from './mutations';
import actions from './actions';
import getters from './getters';

import { OperatotrsState } from '../types';
import { RootState } from '@/store/general.types';

const state: OperatotrsState = {
    operators: [],
    operatorCurrent: {
        id: undefined,    
        name: '',
        email: '',
        isSuperuser: false,
        isActive: false,
    },
    count: 0,
};

const operators: Module<OperatotrsState, RootState> = {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};

export default operators;