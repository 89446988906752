import { Login, Response } from '@/store/general.types';

import { instance } from '../plugins/axios';

import { IPaymentSort } from '@/modules/purchases/types';

interface Headers {
    header: string;
    value: string | null;
}
export interface ChangeParams {
    price: number;
    monthlyPrice: number;
    numMonths: number;
    currency: string;
}

class AdminApi {
    async auth(params: Login): Promise<Response> {
        return instance.post('/admin/token', params).catch((e) => e.response);
    }

    async refresh(): Promise<Response> {
        return instance.post('/admin/token/refresh').catch((e) => e.response);
    }

    async logout(): Promise<Response> {
        return instance.post('/admin/logout').catch((e) => e.response);
    }

    async setHeaders(params: Headers) {
        params.value
            ? (instance.defaults.headers.common[params.header] = params.value)
            : delete instance.defaults.headers.common[params.header];
    }

    async getSubscribes(): Promise<Response> {
        return instance.get('/admin/edit_settings/sub').catch((e) => e.response);
    }

    async deleteSubscribes(id: number): Promise<Response> {
        return instance.delete(`/admin/edit_settings/sub/${id}`).catch((e) => e.response);
    }

    async changeSubscribes(id: number, params: ChangeParams): Promise<Response> {
        return instance
            .patch(`/admin/edit_settings/sub/${id}`, params)
            .catch((e) => e.response);
    }

    async createSubscribes(params: ChangeParams): Promise<Response> {
        return instance.post('/admin/edit_settings/sub', params).catch((e) => e.response);
    }

    async getSubscribe(id: number): Promise<Response> {
        return instance.get(`/admin/edit_settings/sub/${id}`).catch((e) => e.response);
    }

    async getTrialPeriod() {
        return instance.get('/admin/settings-list').catch((e) => e.response);
    }

    async changeTrialPeriod(payload: { trial_period: number }) {
        return instance.post('/admin/edit-settings', payload).catch((e) => e.response);
    }

    async getPayments(payload: IPaymentSort): Promise<Response> {
        return instance.get('/admin/get-payments', { params: payload }).catch((e) => e.response);
    }
}

export default new AdminApi();
