import { ActionTree } from 'vuex';

import { LoginState } from './types';
import { actionsNames, mutationsNames } from './names';
import {
    RootState,
    Login,
    Response,
} from '@/store/general.types';

import api from '@/services/admin.service';

let updateInterval: any;

const actions: ActionTree<LoginState, RootState> = {
    async [actionsNames.getToken]({ commit }, login: Login) {
        const res: Response = await api.auth(login);

        if (res && res.status === 200) {
            commit(mutationsNames.SET_TOKEN, res.data.access);

            await api.setHeaders({
                header: 'Authorization',
                value: `JWT ${res.data.access}`,
            });

            return true;
        }

        return false;
    },

    async [actionsNames.refresh]({ commit }) {
        const res = await api.refresh();

        if (res && res.status === 200 && res.data.access) {
            commit(mutationsNames.SET_TOKEN, res.data.access);

            await api.setHeaders({
                header: 'Authorization',
                value: `JWT ${res.data.access}`,
            });

            return true;
        }
        return false;
    },

    async [actionsNames.regularRefresh]({ dispatch }) {
        updateInterval = setInterval(() => {
            dispatch(actionsNames.refresh);
        }, 1000 * 60 * 4.9);
    },

    async [actionsNames.logout]({ commit }) {
        const res = await api.logout();

        if (res && res.status === 200) {
            clearInterval(updateInterval);
            await api.setHeaders({ header: 'Authorization', value: null });
            commit(mutationsNames.SET_ADMIN_INFO, null);
            commit(mutationsNames.SET_TOKEN, null);
            return true;
        }

        return false;
    },
};

export default actions;