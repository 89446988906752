import { addModuleName } from '@/tools/addModuleNames';

const MODULE_NAME = 'login';

/* eslint-disable */
enum mutationsNames {
    SET_TOKEN = 'SET_TOKEN', 
    SET_ADMIN_INFO = 'SET_ADMIN_INFO',
    UPDATE_ERROR = 'UPDATE_ERROR',
}

enum actionsNames {
    getToken = 'getToken',
    refresh = 'refresh',
    regularRefresh = 'regularRefresh',
    logout = 'logout',
}

enum gettersNames {
    token = 'token',
    admin = 'admin',
}
/* eslint-enable */

const loginMutations = {
    [mutationsNames.SET_TOKEN]: addModuleName(
        MODULE_NAME,
        mutationsNames.SET_TOKEN,
    ),

    [mutationsNames.SET_ADMIN_INFO]: addModuleName(
        MODULE_NAME,
        mutationsNames.SET_ADMIN_INFO,
    ),

    [mutationsNames.UPDATE_ERROR]: addModuleName(
        MODULE_NAME,
        mutationsNames.UPDATE_ERROR,
    ),
};

const loginActions = {
    [actionsNames.getToken]: addModuleName(
        MODULE_NAME,
        actionsNames.getToken,
    ),

    [actionsNames.refresh]: addModuleName(
        MODULE_NAME,
        actionsNames.refresh,
    ),

    [actionsNames.regularRefresh]: addModuleName(
        MODULE_NAME,
        actionsNames.regularRefresh,
    ),

    [actionsNames.logout]: addModuleName(
        MODULE_NAME,
        actionsNames.logout,
    ),
};

const loginGetters = {
    [gettersNames.token]: addModuleName(
        MODULE_NAME,
        gettersNames.token,
    ),

    [gettersNames.admin]: addModuleName(
        MODULE_NAME,
        gettersNames.admin,
    ),
};

export {
    mutationsNames,
    loginMutations,
    actionsNames,
    loginActions,
    gettersNames,
    loginGetters,
};