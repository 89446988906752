import { ActionTree } from 'vuex';
import { actionsNames, mutationsNames } from './names';
import adminService from '@/services/admin.service';
import { RootState } from '@/store/general.types';
import { PurchasesState, IPaymentSort } from '../types';

const actions: ActionTree<PurchasesState, RootState> = {
    async [actionsNames.getPaymentsApi]({ commit }, params: IPaymentSort): Promise<void> {
        const res = await adminService.getPayments(params);

        if (res && res.data?.items) {
            commit(mutationsNames.SET_PAYMENTS_DATA_TO_STATE, res.data);
        }
    },
};

export default actions;
