import { Module } from 'vuex';
import mutations from './mutations';
import actions from './actions';
import { DashboardState } from '../types';
import { RootState } from '@/store/general.types';

const state: DashboardState = {
    paid: 0,
    trialPeriod: 0,
    limit_dreams: 0,
};

const dashboard: Module<DashboardState, RootState> = {
    namespaced: true,
    state,
    mutations,
    actions,
};

export default dashboard;