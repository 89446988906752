import { initApp } from '@/tools/initApp';

import store from '@/store';

import VueRouter, { Route, NavigationGuardNext } from 'vue-router';

function before(router: VueRouter) {
    router.beforeEach(
        async (to: Route, from: Route, next: NavigationGuardNext) => {
            const { login, loading } = store.state;

            if (loading.initialization) {
                await initApp(store);
            }

            const publicPages = ['/login'];
            const authRequired = publicPages.includes(to.path);
        
            if (!login.access) {
                if (!authRequired) {
                    return next('/login');
                } 
                return next();
                
            }

            if (to.path === '/login' && !loading.initialization) {
                return next('/');
            }

            if (login.access && to.path === '/login') {
                return next('/');
            }
        
            next();
        },
    );
}

export default before;
