import { MutationTree } from 'vuex';
import { mutationsNames } from './names';
import { LoadingState } from './types';

const mutations: MutationTree<LoadingState> = {
    [mutationsNames.TOGGLE_GLOBAL_LOADER](state: LoadingState, payload: boolean) {
        state.loader = payload;
    },

    [mutationsNames.TOGGLE_INIT_STATUS](state: LoadingState, payload: boolean) {
        state.initialization = payload;
    },
};

export default mutations;