import { MutationTree } from 'vuex';
import { mutationsNames } from './names';
import { OperatotrsState, Operator } from '../types';

const mutations: MutationTree<OperatotrsState> = {
    [mutationsNames.SET_OPERATORS](state: OperatotrsState, operators: Array<Operator>) {
        state.operators = operators;
    },

    [mutationsNames.SET_COUNT](state: OperatotrsState, count: number) {
        state.count = count;
    },

    [mutationsNames.SET_OPERATOR](state: OperatotrsState, operator: Operator) {    
        state.operatorCurrent = operator;
    },
};

export default mutations;