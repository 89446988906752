import { RouteConfig } from 'vue-router';

const Purchases: RouteConfig = {
    path: '/purchases',
    name: 'Purchases',

    // eslint-disable-next-line
    component: () => import(/* webpackChunkName: "purchases" */ './Purchases.vue'),
};

export default Purchases;
