import { RouteConfig } from 'vue-router';

const Login: RouteConfig = {
    path: '/login',
    name: 'Login',
    // eslint-disable-next-line
  component: () => import(/* webpackChunkName: "auth" */ './Login.vue'),
};

export default Login;
