import { addModuleName } from '@/tools/addModuleNames';

const MODULE_NAME = 'subscription';

/* eslint-disable */
enum mutationsNames {
    SET_SUBSCRIBES_DATA_TO_STATE = 'SET_SUBSCRIBES_DATA_TO_STATE',
    SET_SUBSCRIBE_DATA = 'SET_SUBSCRIBE_DATA',
}

enum actionsNames {
    getSubscribesApi = 'getSubscribesApi',
    getSubscribeApi = 'getSubscribeApi',
    deleteSubscribesApi = 'deleteSubscribesApi',
    changeSubscribesApi = 'changeSubscribesApi',
    createSubscribesApi = 'createSubscribesApi',
}

const subscriptionMutations = {
    [mutationsNames.SET_SUBSCRIBES_DATA_TO_STATE]: addModuleName(
        MODULE_NAME,
        mutationsNames.SET_SUBSCRIBES_DATA_TO_STATE,
    ),

    [mutationsNames.SET_SUBSCRIBE_DATA]: addModuleName(
        MODULE_NAME,
        mutationsNames.SET_SUBSCRIBE_DATA,
    ),
};

const subscriptionActions = {
    [actionsNames.getSubscribesApi]: addModuleName(
        MODULE_NAME,
        actionsNames.getSubscribesApi,
    ),

    [actionsNames.getSubscribeApi]: addModuleName(
        MODULE_NAME,
        actionsNames.getSubscribeApi,
    ),

    [actionsNames.deleteSubscribesApi]: addModuleName(
        MODULE_NAME,
        actionsNames.deleteSubscribesApi,
    ),

    [actionsNames.changeSubscribesApi]: addModuleName(
        MODULE_NAME,
        actionsNames.changeSubscribesApi,
    ),

    [actionsNames.createSubscribesApi]: addModuleName(
        MODULE_NAME,
        actionsNames.createSubscribesApi,
    ),
};

export {
    mutationsNames,
    subscriptionMutations,
    actionsNames,
    subscriptionActions,
};