import { RouteConfig } from 'vue-router';

const Operators: RouteConfig = {
    path: '/operators',
    name: 'Operators',
    redirect: { name: 'OperatorList' },
    // eslint-disable-next-line
    component: () => import(/* webpackChunkName: "operators" */ './Index.vue'),
    children: [
        {
            path: 'list',
            name: 'OperatorList',
            // eslint-disable-next-line
            component: () => import(/* webpackChunkName: "operator-list" */ './Operators.vue'),
        },
        {
            path: ':id',
            name: 'OperatorItem',
            // eslint-disable-next-line
            component: () => import(/* webpackChunkName: "operator-item" */ './Operator.vue'),
        },
    ],
};

export default Operators;
