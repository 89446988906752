import { addModuleName } from '@/tools/addModuleNames';

const MODULE_NAME = 'operators';

/* eslint-disable */
enum mutationsNames {
    SET_OPERATORS = 'SET_OPERATORS',
    SET_COUNT = 'SET_COUNT',
    SET_OPERATOR = 'SET_OPERATOR',
}

enum actionsNames {
    fetchOperators = 'fetchOperators',
    operatorResetPassword = 'operatorResetPassword',
    fetchOperator = 'fetchOperator',
    editOperator = 'editOperator',
    promoteOperator = 'promoteOperator',
    demoteOperator = 'demoteOperator',
    createOperator = 'createOperator',
    blockOperator = 'blockOperator',
    unBlockOperator = 'unBlockOperator',
}

enum gettersNames {
    operators = 'operators',
    operatorsCount = 'operatorsCount',
    operatorCurrent = 'operatorCurrent',
}
/* eslint-enable */

const operatorsMutations = {
    [mutationsNames.SET_OPERATORS]: addModuleName(
        MODULE_NAME,
        mutationsNames.SET_OPERATORS,
    ),

    [mutationsNames.SET_COUNT]: addModuleName(
        MODULE_NAME,
        mutationsNames.SET_COUNT,
    ),

    [mutationsNames.SET_OPERATOR]: addModuleName(
        MODULE_NAME,
        mutationsNames.SET_OPERATOR,
    ),
};

const operatorsActions = {
    [actionsNames.fetchOperators]: addModuleName(
        MODULE_NAME,
        actionsNames.fetchOperators,
    ),

    [actionsNames.operatorResetPassword]: addModuleName(
        MODULE_NAME,
        actionsNames.operatorResetPassword,
    ),

    [actionsNames.fetchOperator]: addModuleName(
        MODULE_NAME,
        actionsNames.fetchOperator,
    ),

    [actionsNames.editOperator]: addModuleName(
        MODULE_NAME,
        actionsNames.editOperator,
    ),

    [actionsNames.promoteOperator]: addModuleName(
        MODULE_NAME,
        actionsNames.promoteOperator,
    ),

    [actionsNames.demoteOperator]: addModuleName(
        MODULE_NAME,
        actionsNames.demoteOperator,
    ),

    [actionsNames.createOperator]: addModuleName(
        MODULE_NAME,
        actionsNames.createOperator,
    ),

    [actionsNames.blockOperator]: addModuleName(
        MODULE_NAME,
        actionsNames.blockOperator,
    ),

    [actionsNames.unBlockOperator]: addModuleName(
        MODULE_NAME,
        actionsNames.unBlockOperator,
    ),
};

const operatorsGetters = {
    [gettersNames.operators]: addModuleName(
        MODULE_NAME,
        gettersNames.operators,
    ),

    [gettersNames.operatorsCount]: addModuleName(
        MODULE_NAME,
        gettersNames.operatorsCount,
    ),

    [gettersNames.operatorCurrent]: addModuleName(
        MODULE_NAME,
        gettersNames.operatorCurrent,
    ),
};

export {
    mutationsNames,
    operatorsMutations,
    actionsNames,
    operatorsActions,
    gettersNames,
    operatorsGetters,
};