import { Module } from 'vuex';
import mutations from './mutations';
import actions from './actions';
import { LoginState } from './types';
import { RootState } from '@/store/general.types';

const state: LoginState = {
    access: null,
    preloader: false,
    admin: {},
    error: '',
};

const loading: Module<LoginState, RootState> = {
    namespaced: true,
    state: () => state,
    mutations,
    actions,
};

export default loading;