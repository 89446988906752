import '@mdi/font/css/materialdesignicons.css';
import 'material-design-icons-iconfont/dist/material-design-icons.css';
import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

type Icons = {
  iconfont: any;
};

const icons: Icons = {
    iconfont: 'mdi',  
};

export default new Vuetify({
    icons,
});
