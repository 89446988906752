import { RouteConfig } from 'vue-router';

const Subscriptions: RouteConfig = {
    path: '/subscriptions',
    name: 'Subscriptions',
    // eslint-disable-next-line
    component: () => import(/* webpackChunkName: "subscriptions" */ './index.vue'),
    redirect: { name: 'SubscriptionList' },
    children: [
        {
            path: 'list',
            name: 'SubscriptionList',
            component: () =>
                // eslint-disable-next-line
                import(/* webpackChunkName: "subscriptions-list" */ './Subscriptions.vue'),
        },
        {
            path: 'create',
            name: 'SubscriptionCreate',
            component: () =>
                // eslint-disable-next-line
                import(/* webpackChunkName: "subscription-create" */ './SubscriptionCreate.vue'),
        },
        {
            path: ':id',
            name: 'SubscriptionItem',
            component: () =>
                // eslint-disable-next-line
                import(/* webpackChunkName: "subscription-item" */ './Subscription.vue'),
        },
    ],
};

export default Subscriptions;
