import Vue from 'vue';

import Vuex, { StoreOptions } from 'vuex';

import login from './modules/login/state';
import dashboard from '@/modules/dashboard/store/state';
import operators from '@/modules/operators/store/state';
import loading from './modules/loading/state';
import subscription from '@/modules/subscription/store/state';
import purchases from '@/modules/purchases/store/state';

import { RootState } from './general.types';

Vue.use(Vuex);

const store: StoreOptions<RootState> = {
    state: {
        version: '1.0.0',
    } as RootState,

    modules: {
        login,
        dashboard,
        operators,
        loading,
        subscription,
        purchases,
    },
};

export default new Vuex.Store<RootState>(store);
