import { addModuleName } from '@/tools/addModuleNames';

const MODULE_NAME = 'loading';

/* eslint-disable */
enum mutationsNames {
    TOGGLE_INIT_STATUS = 'TOGGLE_INIT_STATUS',
    TOGGLE_GLOBAL_LOADER = 'TOGGLE_GLOBAL_LOADER',
}
/* eslint-enable */

const loadingMutations = {
    [mutationsNames.TOGGLE_INIT_STATUS]: addModuleName(
        MODULE_NAME,
        mutationsNames.TOGGLE_INIT_STATUS,
    ),

    [mutationsNames.TOGGLE_GLOBAL_LOADER]: addModuleName(
        MODULE_NAME,
        mutationsNames.TOGGLE_GLOBAL_LOADER,
    ),
};

export {
    mutationsNames,
    loadingMutations,
};