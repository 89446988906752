import { addModuleName } from '@/tools/addModuleNames';

const MODULE_NAME = 'purchases';

/* eslint-disable */
enum mutationsNames {
    SET_PAYMENTS_DATA_TO_STATE = 'SET_PAYMENTS_DATA_TO_STATE',
}

enum actionsNames {
    getPaymentsApi = 'getPaymentsApi',
}
/* eslint-enable */

const purchasesMutations = {
    [mutationsNames.SET_PAYMENTS_DATA_TO_STATE]: addModuleName(
        MODULE_NAME,
        mutationsNames.SET_PAYMENTS_DATA_TO_STATE,
    ),
};

const purchasesActions = {
    [actionsNames.getPaymentsApi]: addModuleName(
        MODULE_NAME,
        actionsNames.getPaymentsApi,
    ),
};

export {
    mutationsNames,
    purchasesMutations,
    actionsNames,
    purchasesActions,
};