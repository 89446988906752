import { Module } from 'vuex';
import mutations from './mutations';
import { LoadingState } from './types';
import { RootState } from '@/store/general.types';

const state: LoadingState = {
    initialization: true,
    loader: false,
};

const loading: Module<LoadingState, RootState> = {
    namespaced: true,
    state: () => state,
    mutations,
};

export default loading;