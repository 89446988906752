import { Store } from 'vuex';
import { loadingMutations } from '@/store/modules/loading/names';
import { loginActions } from '@/store/modules/login/names';
import { RootState } from '@/store/general.types';

export const initApp = async (store: Store<RootState>) => {
    const refresh = await store.dispatch(loginActions.refresh);

    if (refresh) {
        await store.dispatch(loginActions.regularRefresh);
    }

    store.commit(loadingMutations.TOGGLE_GLOBAL_LOADER, false);
};