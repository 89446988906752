import { MutationTree } from 'vuex';
import { mutationsNames } from './names';
import { DashboardState } from '../types';

const mutations: MutationTree<DashboardState> = {
    [mutationsNames.SET_PAID](state, paid: number) {
        state.paid = paid;
    },

    [mutationsNames.SET_TRIAL_PERIOD](state, payload: number) {
        state.trialPeriod = payload;
    },

    [mutationsNames.SET_TRIAL_LIMIT_DREAMS](state, payload: number) {
        state.limit_dreams = payload;
    },
};

export default mutations;