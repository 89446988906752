import Vue from 'vue';

import VueRouter, { RouteConfig } from 'vue-router';

import Login from '@/modules/login/router';

import Dashboard from '@/modules/dashboard/router';

import Operators from '@/modules/operators/router';

import before from './hooks/before';

import Subscription from '@/modules/subscription/router';

import Purchases from '@/modules/purchases/router';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
    {
        path: '/auth',
        name: 'Auth',
        component: () =>
            import(
                // eslint-disable-next-line
                /* webpackChunkName: "layoutAuth" */ '@/_layout/LayoutAuth.vue'
            ),
        redirect: { name: 'Login' },
        children: [Login],
    },
    {
        path: '/',
        name: 'Layout',
        // eslint-disable-next-line
        component: () => import(/* webpackChunkName: "layout" */ '@/_layout/Layout.vue'),
        redirect: { name: 'Dashboard' },
        meta: { requiresAuth: true },
        children: [
            Dashboard,
            Operators,
            Subscription,
            Purchases,
        ],
    },
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.VUE_APP_BASE_URL,
    routes,
});

before(router);

export default router;
