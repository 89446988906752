import { Module } from 'vuex';

import mutations from './mutations';
import actions from './actions';

import { SubscribtionState } from '../types';
import { RootState } from '@/store/general.types';

const state: SubscribtionState = {
    subscribesData: [],
    subscribeData: {},
};

const subscription: Module<SubscribtionState, RootState> = {
    namespaced: true,
    state,
    mutations,
    actions,
};

export default subscription;