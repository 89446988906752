import { MutationTree } from 'vuex';
import { mutationsNames } from './names';
import { SubscribtionState } from '../types';

const mutations: MutationTree<SubscribtionState> = {
    [mutationsNames.SET_SUBSCRIBES_DATA_TO_STATE](state, payload) {
        state.subscribesData = payload;
    },

    [mutationsNames.SET_SUBSCRIBE_DATA](state, payload) {
        state.subscribeData = payload;
    },
};

export default mutations;