import { ActionTree } from 'vuex';
import { actionsNames, mutationsNames } from './names';
import { RootState } from '@/store/general.types';
import {
    OperatotrsState,
    OperatorsQuery,
    OperatorsQueryResetPass,
    OperatorQuery,
    OperatorUpdate,
    OperatorPromote,
    OperatorCreate,
    Operator,
} from '../types';

import operatorsApi from '../api';

const actions: ActionTree<OperatotrsState, RootState> = {
    [actionsNames.fetchOperators]: async (
        { commit },
        payload: OperatorsQuery,
    ) => {
        const res = await operatorsApi.getOperators(payload);

        commit(mutationsNames.SET_OPERATORS, res.result);
        commit(mutationsNames.SET_COUNT, res.count);

        return res;
    },

    [actionsNames.operatorResetPassword]: async ({}, payload: OperatorsQueryResetPass) =>
        operatorsApi.operatorResetPass(payload),

    [actionsNames.fetchOperator]: async ({ commit }, payload: OperatorQuery) => {
        const res = await operatorsApi.getOperator(payload);
        commit(mutationsNames.SET_OPERATOR, res);
    },

    [actionsNames.editOperator]: async ({ commit }, payload: OperatorUpdate) => {
        const res = await operatorsApi.updateOperator(payload);
        commit(mutationsNames.SET_OPERATORS, res);
    },

    [actionsNames.promoteOperator]: async ({ commit }, payload: OperatorPromote) => {
        const res = await operatorsApi.promoteOperator(payload);
        commit(mutationsNames.SET_OPERATOR, res);
    },

    [actionsNames.demoteOperator]: async ({ commit }, payload: OperatorPromote) => {
        const res = await operatorsApi.demoteOperator(payload);
        commit(mutationsNames.SET_OPERATOR, res);
    },

    [actionsNames.createOperator]: async (
        {},
        payload: OperatorCreate,
    ): Promise<Operator> => operatorsApi.opertorCreate(payload),

    [actionsNames.blockOperator]: async (
        { commit },
        payload: OperatorPromote,
    ): Promise<Operator> => {
        const res = await operatorsApi.blockOperator(payload);
        commit(mutationsNames.SET_OPERATOR, res);
        return res;
    },

    [actionsNames.unBlockOperator]: async (
        { commit },
        payload: OperatorPromote,
    ): Promise<Operator> => {
        const res = await operatorsApi.unBlockOperator(payload);
        commit(mutationsNames.SET_OPERATOR, res);
        return res;
    },
};

export default actions;
