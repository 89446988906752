import { ActionTree } from 'vuex';
import { actionsNames, mutationsNames } from './names';
import { RootState } from '@/store/general.types';

import api from '@/services/admin.service';

import { DashboardState } from '../types';

const actions: ActionTree<DashboardState, RootState> = {
    async [actionsNames.getTrialPeriod]({ commit }) {
        const res = await api.getTrialPeriod();
        if (res && res.status === 200) {
            commit(mutationsNames.SET_TRIAL_PERIOD, res.data[0].trial_period / 60 / 60 / 24);
            commit(mutationsNames.SET_TRIAL_LIMIT_DREAMS, res.data[0].limit_dreams);
        }
    },

    async [actionsNames.changeTrialPeriod](
        {},
        payload: { trial_period: number; limit_dreams: number },
    ) {
        await api.changeTrialPeriod(payload);
    },
};

export default actions;