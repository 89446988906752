import { RouteConfig } from 'vue-router';

const Dashboard: RouteConfig = {
    path: '/dashboard',
    name: 'Dashboard',
    // eslint-disable-next-line
  component: () => import(/* webpackChunkName: "auth" */ './Dashboard.vue'),
};

export default Dashboard;
