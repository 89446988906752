import { MutationTree } from 'vuex';
import { mutationsNames } from './names';
import { LoginState } from './types';
import { OperatorItem } from '@/store/general.types';

const mutations: MutationTree<LoginState> = {
    [mutationsNames.SET_TOKEN](state: LoginState, token: string | null) {
        state.access = token;
    },

    [mutationsNames.SET_ADMIN_INFO](state: LoginState, admin: OperatorItem) {
        state.admin = admin;
    },

    [mutationsNames.UPDATE_ERROR](state: LoginState, error: string) {
        state.error = error;
    },
};

export default mutations;