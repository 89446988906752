import { apolloClient } from '@/plugins/apollo';

import {
    OperatorsQuery,
    OperatorQuery,
    OperatorsRespons,
    OperatorsQueryResetPass,
    OperatorsQueryResetPassRes,
    OperatorUpdate,
    OperatorPromote,
    OperatorCreate,
} from './types';

// @ts-ignore
import OPERATORS_ALL from './schemes/Operators.gql';

// @ts-ignore
import OPERATOR_ITEM from './schemes/Operator.gql';

// @ts-ignore
import OPERATOR_CREATE from './schemes/OperatorCreate.gql';

// @ts-ignore
import OPERATOR_UPDATE from './schemes/OperatorUpdate.gql';

// @ts-ignore
import OPERATOR_PROMOTE from './schemes/OperatorPromote.gql';

// @ts-ignore
import OPERATOR_DEMOTE from './schemes/OperatorDemote.gql';

// @ts-ignore
import OPERATOR_RESET_PASSW0RD from './schemes/OperatorResetPassword.gql';

// @ts-ignore
import OPERATOR_BLOCK from './schemes/OperatorBlock.gql';

// @ts-ignore
import OPERATOR_UNBLOCK from './schemes/OperatorUnBlock.gql';

export default {
    async getOperators(payload: OperatorsQuery): Promise<OperatorsRespons> {
        const res = await apolloClient.query({
            query: OPERATORS_ALL,
            variables: {
                input: {
                    ...payload,
                },
            },
        });
    
        return res.data.operators;
    },

    async getOperator(payload: OperatorQuery) {
        try {
            const res = await apolloClient.query({
                query: OPERATOR_ITEM,
                variables: {
                    input: payload,
                },
            });
    
            return res.data.operator;
        } catch (e) {
            return e;
        }
    },

    async operatorResetPass(payload: OperatorsQueryResetPass): Promise<OperatorsQueryResetPassRes> {
        const res = await apolloClient.mutate({
            mutation: OPERATOR_RESET_PASSW0RD,
            variables: {
                input: payload,
            },
        });
    
        return res.data.operatorResetPassword.result;
    },

    async updateOperator(payload: OperatorUpdate) {
        const res = await apolloClient.mutate({
            mutation: OPERATOR_UPDATE,
            variables: {
                input: payload,
            },
        });
    
        return res.data.operatorUpdate.operator;
    },

    async promoteOperator(payload: OperatorPromote) {
        const res = await apolloClient.mutate({
            mutation: OPERATOR_PROMOTE,
            variables: {
                input: payload,
            },
        });
    
        return res.data.operatorPromote.operator;
    },

    async demoteOperator(payload: OperatorPromote) {
        const res = await apolloClient.mutate({
            mutation: OPERATOR_DEMOTE,
            variables: {
                input: payload,
            },
        });
    
        return res.data.operatorDemote.operator;
    },

    async opertorCreate(payload: OperatorCreate) {
        const res = await apolloClient.mutate({
            mutation: OPERATOR_CREATE,
            variables: {
                input: payload,
            },
        });
    
        return res.data.operatorCreate.operator;
    },

    async blockOperator(payload: OperatorPromote) {
        const res = await apolloClient.mutate({
            mutation: OPERATOR_BLOCK,
            variables: {
                input: payload,
            },
        });
    
        return res.data.blockOperator.operator;
    },

    async unBlockOperator(payload: OperatorPromote) {
        const res = await apolloClient.mutate({
            mutation: OPERATOR_UNBLOCK,
            variables: {
                input: payload,
            },
        });
    
        return res.data.unblockOperator.operator;
    },
};