import { Module } from 'vuex';

import mutations from './mutations';
import actions from './actions';

import { PurchasesState } from '../types';
import { RootState } from '@/store/general.types';

const state: PurchasesState = {
    purchasesData: {
        items: [],
        page: 0,
        size: 0,
        total: 0,
    },
};

const purchases: Module<PurchasesState, RootState> = {
    namespaced: true,
    state,
    mutations,
    actions,
};

export default purchases;