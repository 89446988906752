import { addModuleName } from '@/tools/addModuleNames';

const MODULE_NAME = 'dashboard';

/* eslint-disable */
enum mutationsNames {
    SET_PAID = 'SET_PAID',
    SET_TRIAL_PERIOD = 'SET_TRIAL_PERIOD',
    SET_TRIAL_LIMIT_DREAMS = 'SET_TRIAL_LIMIT_DREAMS',
}

enum actionsNames {
    getTrialPeriod = 'getTrialPeriod',
    changeTrialPeriod = 'changeTrialPeriod',
}
/* eslint-enable */

const dashboardMutations = {
    [mutationsNames.SET_PAID]: addModuleName(
        MODULE_NAME,
        mutationsNames.SET_PAID,
    ),

    [mutationsNames.SET_TRIAL_PERIOD]: addModuleName(
        MODULE_NAME,
        mutationsNames.SET_TRIAL_PERIOD,
    ),

    [mutationsNames.SET_TRIAL_LIMIT_DREAMS]: addModuleName(
        MODULE_NAME,
        mutationsNames.SET_TRIAL_LIMIT_DREAMS,
    ),
};

const dashboardActions = {
    [actionsNames.getTrialPeriod]: addModuleName(
        MODULE_NAME,
        actionsNames.getTrialPeriod,
    ),

    [actionsNames.changeTrialPeriod]: addModuleName(
        MODULE_NAME,
        actionsNames.changeTrialPeriod,
    ),
};

export {
    mutationsNames,
    dashboardMutations,
    actionsNames,
    dashboardActions,
};