import Vue from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import apolloProvider from './plugins/apollo';
import './plugins/vuelidate';
import './tools/sentry';

import './assets/scss/entry/main.scss';

Vue.config.productionTip = false;

new Vue({
    router,
    store,
    vuetify,
    apolloProvider,
    render: (h) => h(App),
}).$mount('#app');
