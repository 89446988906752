import { GetterTree } from 'vuex';
import { OperatotrsState } from '../types';
import { gettersNames } from './names';
import { RootState } from '@/store/general.types';

const getters: GetterTree<OperatotrsState, RootState> = {
    [gettersNames.operators](state: OperatotrsState) {
        return state.operators;
    },

    [gettersNames.operatorsCount](state: OperatotrsState) {
        return state.count;
    },

    [gettersNames.operatorCurrent](state: OperatotrsState) {
        return state.operatorCurrent;
    },
};

export default getters;