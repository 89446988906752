import { MutationTree } from 'vuex';
import { mutationsNames } from './names';
import { PurchasesState } from '../types';

const mutations: MutationTree<PurchasesState> = {
    [mutationsNames.SET_PAYMENTS_DATA_TO_STATE](state, payload) {
        state.purchasesData = payload;
    },
};

export default mutations;