import { ActionTree } from 'vuex';

import { actionsNames, mutationsNames } from './names';

import adminService, { ChangeParams } from '@/services/admin.service';

import { RootState } from '@/store/general.types';

import { SubscribtionState } from '../types';

const actions: ActionTree<SubscribtionState, RootState> = {
    async [actionsNames.getSubscribesApi](
        { commit },
    ): Promise<void> {
        const res = await adminService.getSubscribes();
        if (res) {
            commit(mutationsNames.SET_SUBSCRIBES_DATA_TO_STATE, res.data);
        }
    },

    async [actionsNames.getSubscribeApi](
        { commit },
        id: number,
    ): Promise<void> {
        const res = await adminService.getSubscribe(id);

        if (!res) {
            return;
        }

        commit(mutationsNames.SET_SUBSCRIBE_DATA, res.data);
    },

    async [actionsNames.deleteSubscribesApi]({}, id: number): Promise<void> {
        await adminService.deleteSubscribes(id);
    },

    async [actionsNames.changeSubscribesApi](
        { commit },
        params: { id: number; change: ChangeParams },
    ): Promise<void> {
        await adminService.changeSubscribes(params.id, params.change);
        commit(mutationsNames.SET_SUBSCRIBE_DATA, params.change);
    },

    async [actionsNames.createSubscribesApi]({}, params: ChangeParams): Promise<void> {
        await adminService.createSubscribes(params);
    },
};

export default actions;
